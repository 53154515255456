<template>
  <div class="cctv-content">
    <div class="cctv-wrap">
      <div class="cctv-list">
        <div class="cctv-item" v-for="cctv in cctvList" :key="cctv.name">
          <div class="cctv-item-title">
            {{ cctv.title }}
          </div>
          <div class="cctv-preview-list">
            <div class="cctv-preview" v-for="cctvPreview in cctv.cctvPreview" :key="cctvPreview.cctvName">
              <img class="cctv-preview-img" :src="cctvPreview.src" :alt="cctvPreview.cctvName">
              <div class="cctv-preview-info">
                {{ cctvPreview.info }}
                <a class="cctv-link" href="#">Открыть</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button class="create-btn cctv-btn">
        Заказать видеонаблюдение
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return{
      cctvList: [
        {
          title: "Детская площадка",
          name: "playground",
          cctvPreview: [
            {
              info: "Внутренний двор",
              cctvName: "camera-1",
              src: "https://www.geocam.ru/images/photo/gl/or/glory-ave-12k1.jpg",
            },
            {
              info: "Внешний двор",
              cctvName: "camera-2",
              src: "https://www.geocam.ru/images/photo/gl/or/glory-ave-12.jpg",
            },
            {
              info: "Двор",
              cctvName: "camera-3",
              src: "https://www.geocam.ru/images/photo/gl/or/glory-ave-12k.jpg",
            },
          ],
        },
        {
          title: "Подъезд 1",
          name: "entrance",
          cctvPreview: [
            {
              info: "1 этаж",
              cctvName: "camera-4",
              src: "https://www.0-34.ru/images/1-004.jpg",
            },
            {
              info: "2 этаж",
              cctvName: "camera-5",
              src: "https://i.ytimg.com/vi/tiC_J49_oqM/maxresdefault.jpg",
            },
            {
              info: "3 этаж",
              cctvName: "camera-6",
              src: "https://meldana.com/upload/medialibrary/d3a/d3a3f4119c25d89a519adb170eccfa5e.jpg",
            },
          ],
        },
      ],
    };
  },
}
</script>

<style>
</style>